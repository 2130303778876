import {
  EuiFlexGroup,
  EuiPage,
  EuiPageBody,
  EuiPageContent_Deprecated,
  EuiPageContentBody_Deprecated,
  EuiPageHeader,
  EuiSpacer,
  EuiFlexItem,
  EuiButton,
} from "@elastic/eui";
import { React, useState, useEffect } from "react";
import logo from "../images/logo-light.png";
import BottomBar from "./BottomBar";
import DetailsPanel from "./panels/DetailsPanel";
import LivePanel from "./panels/LivePanel";
import GuestsPanel from "./panels/GuestsPanel";
import TalksPanel from "./panels/TalksPanel";
import HostPanel from "./panels/HostPanel";
import mixpanel from "mixpanel-browser";
import { Routes, Route } from "react-router-dom";
import history from "./../utilities";
// import Recordings from "./Login";

function MainPage() {
  const [airtableNumber, setAirtableNumber] = useState(0);

  useEffect(() => {
    fetchAirtableNumber();
  }, []);

  const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

  const fetchAirtableNumber = async () => {
    var Airtable = require("airtable");
    var base = new Airtable({ apiKey: AIRTABLE_API_KEY }).base(
      "appZWXu7c2yDQwGtK"
    );
    let recordsPerPage = [];

    base("livestream")
      .select({
        // maxRecords:

        view: "Main View",
      })
      .eachPage(
        await function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.

          // records.forEach(function (record) {
          //   console.log("Retrieved", record.get("Name"));
          // });

          recordsPerPage.push(records.length);

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          setAirtableNumber(recordsPerPage.reduce((a, b) => a + b, 0));
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };

  const tabs = [
    {
      id: "event",
      isSelected: window.location.pathname === "/event",
      label: "Event Details",
      onClick: () => {
        history.push("/event");
        window.location.reload();
      },
    },
    {
      id: "guests",
      isSelected: window.location.pathname === "/guests",
      label: "Guests",
      onClick: () => {
        history.push("/guests");
        window.location.reload();
      },
    },
    {
      id: "talks",
      isSelected: window.location.pathname === "/schedule",
      label: "Schedule",
      onClick: () => {
        history.push("/schedule");
        window.location.reload();
      },
    },
    {
      id: "host",
      isSelected: window.location.pathname === "/host",
      label: "Meet Your Host",
      onClick: () => {
        history.push("/host");
        window.location.reload();
      },
    },
    {
      id: "live",
      isSelected: window.location.pathname === "/live",
      label: "Watch Live",
      onClick: () => {
        history.push("/live");
        window.location.reload();
      },
    },
    // {
    //   id: "recordings",
    //   isSelected: selectedTab === "recordings",
    //   label: "Recordings",
    //   onClick: () => onSelectedTabChanged("recordings"),
    //   content: <Recordings />,
    // },
  ];
  console.log("state", airtableNumber);

  return (
    <EuiPage paddingSize="none">
      <EuiFlexGroup className="eui-fullHeight">
        <EuiPageBody panelled>
          <EuiPageHeader
            className="ttMargin"
            iconType={logo}
            pageTitle={"Twelve Hours of Trello"}
            rightSideItems={[
              <EuiFlexGroup>
                <EuiFlexItem component="span">
                  <EuiButton
                    color="success"
                    fill
                    iconType="save"
                    href="https://trello.substack.com/"
                    target="_blank"
                    onClick={() => {
                      mixpanel.track("clicked newsletter button");
                    }}
                  >
                    Join the How I Trello Newsletter
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    color="danger"
                    iconType="cheer"
                    fill
                    href="https://www.extra-life.org/index.cfm?fuseaction=donorDrive.participant&participantID=501099"
                    target={"_blank"}
                    onClick={() => {
                      mixpanel.track(`Clicked extra life donate button`);
                    }}
                  >
                    Donate to Extra Life
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>,
            ]}
            tabs={tabs}
          />

          <EuiPageContent_Deprecated
            hasBorder={false}
            hasShadow={false}
            paddingSize="none"
            color="transparent"
            borderRadius="none"
            verticalPosition="center"
            horizontalPosition="center"
          >
            <EuiPageContentBody_Deprecated>
              <Routes>
                <Route
                  path="/"
                  element={<DetailsPanel airtableNumber={airtableNumber} />}
                />
                <Route
                  path="/event"
                  element={<DetailsPanel airtableNumber={airtableNumber} />}
                />
                <Route path="/guests" element={<GuestsPanel />} />
                <Route path="/schedule" element={<TalksPanel />} />
                <Route path="/host" element={<HostPanel />} />
                <Route path="/live" element={<LivePanel />} />
              </Routes>
            </EuiPageContentBody_Deprecated>
            <EuiSpacer size="l" />
          </EuiPageContent_Deprecated>
          <BottomBar />
        </EuiPageBody>
      </EuiFlexGroup>
    </EuiPage>
  );
}

export default MainPage;
