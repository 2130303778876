import britt from "../images/britt.png";
import todd from "../images/todd.png";
import jimmy from "../images/jimmy.png";
import rodney from "../images/rodney.png";
import deepak from "../images/deepak.png";
import shreya from "../images/shreya.png";
import scott from "../images/scott.png";
import lauren from "../images/lauren.png";
import stu from "../images/stu.png";
import vy from "../images/vy.png";
import robin from "../images/robin.png";
import okyu from "../images/okyu.png";
import reinder from "../images/reinder.png";
import derek from "../images/derek.png";
import alberto from "../images/alberto.png";
import craig from "../images/craig.png";
import mark from "../images/mark.png";

export const speakers = [
  {
    name: "Brittany Joiner",
    title: "Automation Architect",
    team: "How I Trello",
    avatar: britt,
    pronouns: "she/her",
  },
  {
    name: "Todd Schiller",
    title: "Founder",
    team: "PixieBrix",
    avatar: todd,
    pronouns: "he/him",
  },
  {
    name: "Jimmy Seddon",
    title: "R&D Tools Administrator",
    team: "Arctic Wolf",
    avatar: jimmy,
    pronouns: "he/him",
  },
  {
    name: "Rodney Nissen",
    title: "Sr. Atlassian Toolsmith",
    team: "Activision Blizzard",
    avatar: rodney,
    pronouns: "he/him",
  },
  {
    name: "Deepak Venkatachalam",
    title: "Engineer",
    team: "Atlassian",
    avatar: deepak,
    pronouns: "he/him",
  },
  {
    name: "Shreya Gupta",
    title: "Developer Advocate",
    team: "Courier",
    avatar: shreya,
    pronouns: "she/her",
  },
  {
    name: "Scott Friesen",
    title: "Trello Consultant",
    team: "Simpletivity",
    avatar: scott,
    pronouns: "he/him",
  },
  {
    name: "Lauren Moon",
    title: "Product Marketing Manager",
    team: "Atlassian",
    avatar: lauren,
    pronouns: "she/her",
  },
  {
    name: "Derek Hamilton",
    title: "Lead Content Designer",
    team: "Atlassian",
    avatar: derek,
    pronouns: "he/him",
  },
  {
    name: "Stu Smith",
    title: "Head of Design",
    team: "Atlassian",
    avatar: stu,
    pronouns: "he/him",
  },
  {
    name: "Vy Tran",
    title: "Attorney",
    team: "My Team",
    avatar: vy,
    pronouns: "she/her",
  },
  {
    name: "Robin Warren",
    title: "Founder",
    team: "Blue Cat",
    avatar: robin,
    pronouns: "he/him",
  },
  {
    name: "Mark Fowles",
    title: "Founder",
    team: "SendBoard",
    avatar: mark,
    pronouns: "he/him",
  },
  // {
  //   name: "Allen Bierbaum",
  //   title: "Founder",
  //   team: "Amazing Fields",
  //   avatar: allen,
  //   pronouns: "he/him",
  // },
  {
    name: "Oyku Sorgun",
    title: "Growth Marketing Manager",
    team: "Hipporello",
    avatar: okyu,
    pronouns: "she/her",
  },
  {
    name: "Reinder Visser",
    title: "Founder",
    team: "Placker",
    avatar: reinder,
    pronouns: "he/him",
  },
  {
    name: "Craig Wilson",
    title: "Head of Product Marketing",
    team: "AppFox",
    avatar: craig,
    pronouns: "he/him",
  },
  {
    name: "Alberto Estevez",
    title: "Head of Product Marketing",
    team: "Crmble",
    avatar: alberto,
    pronouns: "he/him",
  },
];
