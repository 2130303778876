import {
  EuiFlexGroup,
  EuiImage,
  EuiSpacer,
  EuiText,
  EuiShowFor,
  EuiToolTip,
} from "@elastic/eui";
import React from "react";
import brittany from "../../images/britt.png";
import mixpanel from "mixpanel-browser";

export default class LivePanel extends React.Component {
  renderHero = () => {
    return <EuiImage size="l" alt="brittany" src={brittany} />;
  };

  render() {
    return (
      <>
        <EuiFlexGroup justifyContent="center">
          <EuiText>
            <h1>Watch the recording!</h1>
          </EuiText>
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFlexGroup gutterSize="l">
          <EuiShowFor sizes={["xs", "s", "m"]}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/pjPzy5maDBM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </EuiShowFor>
          <EuiShowFor sizes={["l", "xl"]}>
            <iframe
              width="1120"
              height="630"
              src="https://www.youtube.com/embed/pjPzy5maDBM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </EuiShowFor>
        </EuiFlexGroup>
      </>
    );
  }
}
