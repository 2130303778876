import { speakers } from "./speakers";

const findSpeaker = (name) => {
  return speakers[speakers.findIndex((speaker) => speaker.name === name)];
};

export const talks = [
  {
    sessionLength: "15 min",
    sessionTime: "2022-12-09T10:00:00.000Z",
    title: "Welcome and Kickoff",
    description:
      "Quick introduction, get settled in, and preview of upcoming talks for this session.",
    genre: "party 🎉",
    speaker: [findSpeaker("Brittany Joiner")],
  },
  {
    sessionLength: "20 min",
    sessionTime: "2022-12-09T10:15:00.000Z",
    title: "Guide to the best free Power-Ups in Trello",
    description:
      "Discover the best free Power-Ups Trello has to offer and how you can use them to improve your workflows.",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Robin Warren")],
  },
  {
    sessionLength: "20 min",
    sessionTime: "2022-12-09T10:35:00.000Z",
    title: "Placker",
    description:
      "Need to aggregate data and manage multiple boards? Need Gantt charts, card mirroring, or other advanced features for aggregating board info? We'll see how Placker can help.",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Reinder Visser")],
  },
  {
    sessionLength: "20 min",
    sessionTime: "2022-12-09T10:55:00.000Z",
    title: "Hipporello",
    description:
      "Discover how to build power-ups without code. Create custom board views, web portals, business web apps in a couple of hours. We'll go through a use case of building an applicant tracking system on Trello with the Hipporello Apps Power-Up Builder.",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Oyku Sorgun")],
  },
  {
    sessionLength: "20 min",
    sessionTime: "2022-12-09T11:15:00.000Z",
    title: "Bridging the gap between email and Trello",
    description: "Send emails directly inside Trello with Senbdoard!",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Mark Fowles")],
  },
  {
    sessionLength: "20 min",
    sessionTime: "2022-12-09T11:35:00.000Z",
    title: "AppFox",
    description: "Approvals for Trello and more!",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Craig Wilson")],
  },
  {
    sessionLength: "20 min",
    sessionTime: "2022-12-09T11:55:00.000Z",
    title: "Crmble",
    description: "Pro tips for folks managing contacts inside Trello.",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Alberto Estevez")],
  },
  {
    sessionLength: "30 min",
    sessionTime: "2022-12-09T12:15:00.000Z",
    title: "How Britt Converted Her Partner to Trello",
    description:
      "What's it like being with someone who is obsessed with Trello?",
    genre: "learn 🧠",
    speaker: [findSpeaker("Vy Tran")],
  },
  {
    sessionLength: "30 min",
    sessionTime: "2022-12-09T12:45:00.000Z",
    title: "Trello Automation Overview",
    description:
      "Time to review some automation basics so you feel comfortable creating your own rules in your Trello boards! I'll review types of automation and demo how to set it up!",
    genre: "party 🎉",
    speaker: [findSpeaker("Brittany Joiner")],
  },
  {
    sessionLength: "1 hour",
    sessionTime: "2022-12-09T13:15:00.000Z",
    title: "Build a Quest In Trello",
    description:
      "Let's build a Trello board that gamifies chores... or hackathons!",
    genre: "build 🛠",
    speaker: [findSpeaker("Jimmy Seddon")],
  },
  {
    sessionLength: "1 hour",
    sessionTime: "2022-12-09T14:15:00.000Z",
    title: "Customization in Trello : 0 to 100",
    description:
      "We'll join an engineer at Trello to discuss how you can customize your Trello experience in simple ways, more complex ways, and totally advanced ways.",
    genre: "learn 🧠",
    speaker: [findSpeaker("Deepak Venkatachalam")],
  },
  {
    sessionLength: "1 hour",
    sessionTime: "2022-12-09T15:15:00.000Z",
    title: "Automating Your Life With Trello",
    description:
      "We'll discuss and show various ways you can use Trello to automate things in your life outside of work!",
    genre: "build 🛠",
    speaker: [findSpeaker("Rodney Nissen")],
  },
  {
    sessionLength: "30 min",
    sessionTime: "2022-12-09T16:15:00.000Z",
    title: "Trello Throwback",
    description:
      "Only two people at Trello have been there longer than Lauren. Let's chat about Trello through the years.",
    genre: "party 🎉",
    speaker: [findSpeaker("Lauren Moon")],
  },
  {
    sessionLength: "2 hours",
    sessionTime: "2022-12-09T16:45:00.000Z",
    title: "Trello Product Team",
    description:
      "Let's chat with the Trello product and design team about stuff!",
    genre: "build 🛠",
    speaker: [
      findSpeaker("Lauren Moon"),
      findSpeaker("Stu Smith"),
      findSpeaker("Derek Hamilton"),
    ],
  },
  {
    sessionLength: "30 min",
    sessionTime: "2022-12-09T18:45:00.000Z",
    title: "A Trello Themed Lunch and Game Time",
    description:
      "Brain break, but still Trello themed! Let's have tacos, and Trello cupcakes and play some games!",
    genre: "party 🎉",
    speaker: [findSpeaker("Brittany Joiner")],
  },
  {
    sessionLength: "1 hour",
    sessionTime: "2022-12-09T19:15:00.000Z",
    title: "Integrating SMS Notifcations in Trello with Courier",
    description:
      "Ever wish you could get text alerts about upcoming due dates in Trello? Me too! I'm going to try to build one with Courier. I don't plan to build it in this session, but I'll start architecting what I want here.",
    genre: "build 🛠",
    speaker: [findSpeaker("Shreya Gupta")],
  },
  {
    sessionLength: "1 hour",
    sessionTime: "2022-12-09T20:15:00.000Z",
    title: "More Power-Up Fun",
    description:
      "Time to talk about more power-ups that will improve your workflows!",
    genre: "power-up party 🎉",
    speaker: [findSpeaker("Scott Friesen")],
  },
  {
    sessionLength: "45 min",
    sessionTime: "2022-12-09T21:15:00.000Z",
    title: "Sending Data to Trello from Anywhere in the Browser",
    description:
      "How do you send links to Trello with a few key clicks? I use PixieBrix, and I'm obsessed with this workflow. I'll show you how to build it too, and some other cool PixieBrix stuff!",
    genre: "build 🛠",
    speaker: [findSpeaker("Todd Schiller")],
  },
];
