import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import React from "react";
import hero from "../../images/trello-livestream.png";

export default class DetailsPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  renderHero = () => {
    return <EuiImage size="xl" alt="trello livestream banner" src={hero} />;
  };

  render() {
    return (
      <div>
        <EuiFlexGroup gutterSize="l">
          <EuiFlexItem>{this.renderHero()}</EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiEmptyPrompt
            title={
              <span>
                Time to finally answer the question... How long can Brittany
                talk about Trello?{" "}
              </span>
            }
            body={
              <>
                <EuiText>
                  Join the party on{" "}
                  <span className="date">
                    December 9th, starting at 4am CST
                  </span>{" "}
                  for 12 hours of Trello building, learning, and oh yeah -
                  definitely some fun. We'll also be raising money to support
                  local children's hospitals!
                </EuiText>
                <br />
                <EuiText>
                  This event has ended! I plan to do this again in the future so
                  feel free to check back in Oct 2023!
                </EuiText>
              </>
            }
            // actions={[
            //   addCalButtons(sessionOne.dateAndTime, sessionOne.calendarLink),
            // ]}
          />
        </EuiFlexGroup>
      </div>
    );
  }
}
