import {
  EuiButton,
  EuiBottomBar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
} from "@elastic/eui";
import React from "react";
import mixpanel from "mixpanel-browser";

export default class Bottombar extends React.Component {
  render() {
    return (
      <>
        <EuiBottomBar position="static">
          <EuiFlexGroup direction="row" alignItems="center">
            <EuiFlexItem>
              <EuiText>
                <h4>
                  Fundraiser for{" "}
                  <EuiLink
                    external
                    href="https://www.extra-life.org/"
                    target="_blank"
                  >
                    Extra Life
                  </EuiLink>
                </h4>
              </EuiText>
              <EuiText>Supporting local children's hospitals</EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton
                color="primary"
                fill
                href="https://trello.substack.com/"
                target={"_blank"}
                onClick={() => {
                  mixpanel.track(`Clicked newsletter signup`);
                }}
              >
                Check out How I Trello
              </EuiButton>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton
                color="danger"
                fill
                href="https://www.extra-life.org/index.cfm?fuseaction=donorDrive.participant&participantID=501099"
                target={"_blank"}
                onClick={() => {
                  mixpanel.track(`Clicked extra life donate button`);
                }}
              >
                Donate to Extra Life
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiBottomBar>
      </>
    );
  }
}
