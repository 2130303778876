import {
  EuiAvatar,
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
} from "@elastic/eui";
import React from "react";
import { talks } from "../../data/talks";
const moment = require("moment-timezone");

export default class TalksPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEst: false,
    };
  }

  getLocalTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  showTime = (timestamp) => {
    let localTime = moment.tz(timestamp, this.getLocalTimezone());
    let estTime = moment.tz(timestamp, "America/New_York");

    return this.state.showEst
      ? estTime.format("h:mm a z")
      : localTime.format("h:mm a z");
  };

  renderSpeakers = (speakers) => {
    if (speakers.length > 1) {
      return speakers.map((speaker) => (
        <>
          <EuiFlexItem>
            <EuiAvatar
              imageUrl={speaker.avatar}
              size="s"
              name={speaker.name}
              className="xMargin"
            />
            <EuiText>{speaker.name}</EuiText>
          </EuiFlexItem>
        </>
      ));
    } else {
      return (
        <>
          <EuiAvatar
            imageUrl={speakers[0].avatar}
            size="s"
            name={speakers[0].name}
            className="xMargin"
          />
          <EuiText>{speakers[0].name}</EuiText>
        </>
      );
    }
  };

  renderTimeColor = (time) => {
    let color = "";
    switch (time) {
      case "30 min":
        return (color = "primary");
      case "20 min":
        return (color = "accent");
      case "1 hour":
        return (color = "warning");
      case "2 hours":
        return (color = "danger");
      default:
        return (color = "default");
    }
  };

  renderGenreTags = (tagName) => {
    let color = "";
    switch (tagName) {
      case "build 🛠":
        return (color = "primary");
      case "learn 🧠":
        return (color = "success");
      case "party 🎉":
        return (color = "accent");
      case "power-up party 🎉":
        return (color = "warning");
      default:
        return (color = "default");
    }
  };

  columns = [
    {
      field: "sessionTime",
      name: "Time",
      sortable: true,
      render: (sessionTime) => (
        <>
          <EuiIcon type="clock" />
          {this.showTime(sessionTime)}
        </>
      ),
      width: "150px",
    },
    {
      field: "sessionLength",
      name: "Length",
      render: (sessionLength) => (
        <EuiBadge color={this.renderTimeColor(sessionLength)}>
          {sessionLength}
        </EuiBadge>
      ),
      width: "100px",
    },
    {
      field: "title",
      name: "Title",
      sortable: true,
      width: "200px",
    },
    {
      field: "description",
      name: "Description",
      sortable: true,
    },
    {
      field: "speaker",
      name: "Guests",
      sortable: true,
      render: (speaker) => this.renderSpeakers(speaker),
    },
    {
      field: "genre",
      name: "Genre",
      sortable: true,
      render: (genre) => (
        <EuiBadge color={this.renderGenreTags(genre)}>{genre}</EuiBadge>
      ),
      width: "150px",
    },
    // {
    //   name: "Actions",
    //   actions: [
    //     // {
    //     //   name: "Add to cal",
    //     //   description: "Add session to your calendar",
    //     //   type: "icon",
    //     //   icon: "calendar",
    //     //   onClick: (e) => {
    //     //     window.open(e.sessionDetails.calendarLink, "_blank");
    //     //   },
    //     // },
    //     {
    //       name: "Send me a text reminder",
    //       description: "Get a reminder on slack about this session.",
    //       type: "icon",
    //       icon: "bell",
    //       onClick: () => {
    //         window.open("https://forms.gle/RMjnXyhqWervgand8", "_blank");
    //       },
    //     },
    //   ],
    //   width: "100px",
    // },
  ];

  renderShowEstButton() {
    return (
      <EuiButton
        minWidth={"300px"}
        iconType="clock"
        onClick={() => {
          if (!this.state.showEst) {
            this.setState({ showEst: true });
          } else {
            this.setState({ showEst: false });
          }
        }}
      >
        Show times in {this.state.showEst ? "Local" : "EST"}
      </EuiButton>
    );
  }

  render() {
    return (
      <>
        <EuiFlexGroup
          gutterSize="l"
          alignItems="center"
          justifyContent="flexEnd"
        >
          <EuiFlexItem grow={false}>{this.renderShowEstButton()}</EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup className="xMargin">
          <EuiFlexItem>
            <EuiBasicTable
              items={talks}
              columns={this.columns}
              hasActions
              onChange={(e) => {
                this.setState({ sortField: e.sort.field });
                this.setState({ sortDirection: e.sort.direction });
              }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  }
}
