import {
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiText,
  EuiButton,
} from "@elastic/eui";
import React from "react";
import brittany from "../../images/britt.png";
import mixpanel from "mixpanel-browser";

export default class HostPanel extends React.Component {
  renderHero = () => {
    return <EuiImage size="l" alt="brittany" src={brittany} />;
  };

  render() {
    return (
      <>
        <EuiFlexGroup gutterSize="l">
          <EuiEmptyPrompt
            title={<span>👋 I'm Brittany and I love to talk about Trello</span>}
            body={
              <EuiFlexGroup>
                <EuiFlexItem>{this.renderHero()}</EuiFlexItem>
                <EuiFlexItem>
                  <EuiText>
                    By day I'm a software engineer, and by, well, all of the
                    time, I'm a Trello nerd. I love to use Trello to help me
                    work faster and smarter, and I love helping other folks do
                    it too.
                  </EuiText>
                  <br />
                  <EuiText>
                    I'm hosting this event since I don't back down from a
                    challenge and Jimmy Seddon dared me to do it! The goal is to
                    raise money for Extra Life, and to also help more folks
                    learn about Trello and all the amazing things you can do
                    with it.
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            }
            actions={[
              <EuiButton
                color="danger"
                fill
                href="https://www.extra-life.org/index.cfm?fuseaction=donorDrive.participant&participantID=501099"
                iconType="cheer"
                target={"_blank"}
                onClick={() => {
                  mixpanel.track(`Clicked extra life donate button`);
                }}
              >
                Donate to Extra Life
              </EuiButton>,
              <EuiButton
                color="success"
                fill
                iconType="save"
                href="https://trello.substack.com/"
                target="_blank"
                onClick={() => {
                  mixpanel.track("clicked register button");
                }}
              >
                Join the How I Trello newsletter for more Trello updates
              </EuiButton>,
            ]}
          />
        </EuiFlexGroup>
      </>
    );
  }
}
